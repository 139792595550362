import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { num: { type: Number, default: 0 } }
  static targets = [ "hiddenInput", "display" ]

  connect() {
    this.numValue = this.hiddenInputTarget.value
    this.changeValue(this.numValue)
  }
  
  plus() {
    this.changeValue(this.numValue + 1)
  }
  
  minus() {
    this.changeValue(Math.max(this.numValue -1, 0))
  }
  
  changeValue(value) {
    this.numValue = value
    this.hiddenInputTarget.value = value
    this.displayTarget.textContent = value
  }
}